import { StyleSheet } from 'react-native'

const globalStyles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 16
  },
  separator: {
    backgroundColor: '#E9ECEF',
    height: 1,
    marginTop: 20
  },
  heading: {
    marginTop: 16,
    marginBottom: 20,
    fontSize: 18,
    lineHeight: 23
  },
  primaryColor: {
    color: 'rgba(15, 23, 42, 1)'
  },
  secondaryColor: {
    color: 'rgba(15, 23, 42, .5)'
  },
  mainColor: {
    color: '#5836cc'
  },
  bgPrimary: {
    backgroundColor: 'rgba(15, 23, 42, 1)'
  },
  bgSecondary: {
    backgroundColor: 'rgba(15, 23, 42, .5)'
  },
  bgWhite: {
    backgroundColor: '#fff'
  },
  textLight: {
    fontFamily: 'mulish-light'
  },
  text: {
    fontFamily: 'mulish-regular'
  },
  textBold: {
    fontFamily: 'mulish-bold'
  },
  textUppercase: {
    textTransform: 'uppercase'
  },
  textLowercase: {
    textTransform: 'lowercase'
  },
  textCapitalize: {
    textTransform: 'capitalize'
  },
  textCenter: {
    textAlign: 'center'
  },
  disclaimer: {
    marginVertical: 20,
    alignItems: 'center',
    justifyContent: 'flex-end'
  },
  headerText: {
    paddingHorizontal: 16,
    paddingVertical: 16,
    fontSize: 16,
    textTransform: 'uppercase'
  },
  disclaimerText: {
    fontSize: 14
  }
})

export default globalStyles
