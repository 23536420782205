import {
  StyleSheet,
  Text,
  View,
  TouchableWithoutFeedback,
  Platform
} from 'react-native'
import React from 'react'
import { createNativeStackNavigator } from '@react-navigation/native-stack'
import { AntDesign } from '@expo/vector-icons'

// components
import HomeScreen from '../screens/HomeScreen'
import PostDetailsScreen from '../screens/PostDetailsScreen'
import PostTagsScreen from '../screens/PostTagsScreen'
import CategoryScreen from '../screens/CategoryScreen'

// stack
const Stack = createNativeStackNavigator()

const HomeStack = ({ navigation }) => {
  return (
    <Stack.Navigator
      screenOptions={{
        headerShadowVisible: false,
        headerStyle: {
          backgroundColor: '#fff'
        },
        headerTitleStyle: {
          fontFamily: 'mulish-bold',
          color: 'rgba(15, 23, 42, 1)'
          // width: 900,
          // textAlign: 'center'
        },
        headerTitleAlign: 'center'
      }}
    >
      <Stack.Screen
        options={{
          headerShown: true,
          title: 'TECH BLOG',
          headerTitleAlign: 'center'
        }}
        name="HomeScreen"
        component={HomeScreen}
      />
      <Stack.Screen
        options={{
          headerShown: true,
          title: '',
          headerLeft: () => (
            <View style={{ marginLeft: Platform.OS === 'web' && 16 }}>
              <AntDesign
                name="leftcircleo"
                size={26}
                color="rgba(15, 23, 42, 1)"
                onPress={() => {
                  navigation.goBack()
                }}
              />
            </View>
          )
        }}
        name="PostDetailsScreen"
        component={PostDetailsScreen}
      />
      <Stack.Screen
        options={{
          headerShown: true,
          title: '',
          headerTitleAlign: 'center',
          headerLeft: () => (
            <View style={{ marginLeft: Platform.OS === 'web' && 16 }}>
              <AntDesign
                name="leftcircleo"
                size={26}
                color="rgba(15, 23, 42, 1)"
                onPress={() => {
                  navigation.goBack()
                }}
              />
            </View>
          )
        }}
        name="PostTagsScreen"
        component={PostTagsScreen}
      />
    </Stack.Navigator>
  )
}

const styles = StyleSheet.create({
  iconWrapper: {
    width: 40,
    height: 40,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 20
  }
})

export default HomeStack
