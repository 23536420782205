import { StyleSheet, Text, View } from 'react-native'
import React from 'react'

// styles
import globalStyles from '../styles/global'

const PostFilterTitle = ({ term, filterBy, size }) => {
  return (
    <View style={styles.titleContainer}>
      <Text
        style={[
          globalStyles.textBold,
          globalStyles.primaryColor,
          styles.titleText
        ]}
      >
        Posts including
      </Text>
      <View style={[styles.titleTextWrap]}>
        <Text
          style={[
            globalStyles.textBold,
            globalStyles.primaryColor,
            styles.titleText,
            styles.titleHighlight
          ]}
        >
          {`${term}`}
        </Text>
      </View>
      <Text
        style={[
          globalStyles.textBold,
          globalStyles.primaryColor,
          styles.titleText
        ]}
      >
        {filterBy} ({size} {size === 1 ? 'post' : 'posts'}).
      </Text>
    </View>
  )
}

const styles = StyleSheet.create({
  titleContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    padding: 16,
    marginBottom: 8
  },
  titleTextWrap: {
    borderRadius: 20,
    backgroundColor: 'rgba(88, 54, 204, .1)',
    marginHorizontal: 6,
    paddingHorizontal: 12,
    paddingBottom: 2
  },
  titleText: {
    fontSize: 18,
    lineHeight: 23,
    paddingTop: 0,
    paddingBottom: 1
  },
  titleHighlight: {
    flexDirection: 'column',
    color: 'rgba(88, 54, 204, 1)',
    textTransform: 'lowercase'
  }
})

export default PostFilterTitle
