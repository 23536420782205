import { StyleSheet, Text, View } from 'react-native'
import React, { useState } from 'react'

// styles
import globalStyles from '../styles/global'

// components
import PostTagList from '../components/PostTagList'
import PostFilterTitle from '../components/PostFilterTitle'

const PostTagsScreen = ({ route, navigation }) => {
  const { tagId, tagName } = route.params

  const [page, setPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  const [totalItems, setTotalItems] = useState(0)
  const [latestPosts, setLatestPosts] = useState([])
  const [reachedToEnd, setReachedToEnd] = useState(false)
  const [firstFetch, setFirstFetch] = useState(true)

  const fetchMorePosts = () => {
    if (reachedToEnd) return

    setPage((prevPage) => prevPage + 1)
    setFirstFetch(false)

    // if (page === totalPages) setReachedToEnd(true)
    if (page === totalPages - 1) setReachedToEnd(true)
  }

  const navDetails = (post) => {
    navigation.navigate('PostDetailsScreen', {
      postId: post.id
    })
  }

  return (
    <View style={styles.container}>
      <PostFilterTitle term={tagName} filterBy="tag" size={totalItems} />
      <PostTagList
        tagId={tagId}
        page={page}
        setPage={setPage}
        totalPages={totalPages}
        setTotalPages={setTotalPages}
        totalItems={totalItems}
        setTotalItems={setTotalItems}
        fetchMorePosts={fetchMorePosts}
        latestPosts={latestPosts}
        setLatestPosts={setLatestPosts}
        reachedToEnd={reachedToEnd}
        setReachedToEnd={setReachedToEnd}
        firstFetch={firstFetch}
        setFirstFetch={setFirstFetch}
        navDetails={navDetails}
      />
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    marginBottom: 20
  },
  postListContainer: {
    flex: 1
  }
})

export default PostTagsScreen
