import { Platform, StyleSheet } from 'react-native'

const tagsStyles = StyleSheet.create({
  body: {
    fontFamily: 'mulish-regular',
    color: 'rgba(15, 23, 42, .7)'
  },
  a: {
    color: '#5836cc',
    textDecorationLine: 'none'
  },
  strong: {
    fontWeight: 'normal',
    fontFamily: 'mulish-bold',
    color: 'rgba(15, 23, 42, 1)'
  },
  p: {
    marginTop: 0,
    marginBottom: 16,
    fontSize: 14,
    lineHeight: 19
  },
  h3: {
    fontWeight: 'normal',
    fontFamily: 'mulish-bold',
    color: 'rgba(15, 23, 42, 1)',
    marginTop: 0,
    marginBottom: 16,
    fontSize: 17,
    lineHeight: 22
  },
  ul: {
    fontSize: 13,
    paddingRight: 15,
    fontWeight: 'bold',
    justifyContent: 'center'
  },
  ol: {
    fontSize: 13,
    paddingRight: 15,
    fontWeight: 'bold',
    justifyContent: 'center'
  },
  li: {
    fontWeight: 'normal',
    fontFamily: 'mulish-regular',
    fontSize: 14,
    marginTop: 0
  }
})

const classesStyles = StyleSheet.create({})

const bodyStyles = StyleSheet.create({
  // The main container
  body: {
    fontFamily: 'mulish-regular',
    color: 'rgba(15, 23, 42, .7)',
    fontSize: 14,
    lineHeight: 19
  },

  // Headings
  heading1: {
    flexDirection: 'row',
    fontSize: 22,
    lineHeight: 27,
    fontFamily: 'mulish-bold',
    marginTop: 6,
    marginBottom: 16,
    color: 'rgba(15, 23, 42, .9)'
  },
  heading2: {
    flexDirection: 'row',
    fontSize: 20,
    lineHeight: 25,
    fontFamily: 'mulish-bold',
    marginTop: 6,
    marginBottom: 16,
    color: 'rgba(15, 23, 42, .9)'
  },
  heading3: {
    flexDirection: 'row',
    fontSize: 17,
    lineHeight: 22,
    fontFamily: 'mulish-bold',
    marginTop: 6,
    marginBottom: 16,
    color: 'rgba(15, 23, 42, .9)'
  },
  heading4: {
    flexDirection: 'row',
    fontSize: 16,
    lineHeight: 21,
    fontFamily: 'mulish-bold',
    marginTop: 6,
    marginBottom: 16,
    color: 'rgba(15, 23, 42, .9)'
  },
  heading5: {
    flexDirection: 'row',
    fontSize: 13,
    lineHeight: 18,
    fontFamily: 'mulish-bold',
    marginTop: 6,
    marginBottom: 16,
    color: 'rgba(15, 23, 42, .9)'
  },
  heading6: {
    flexDirection: 'row',
    fontSize: 11,
    lineHeight: 16,
    fontFamily: 'mulish-bold',
    marginTop: 6,
    marginBottom: 16,
    color: 'rgba(15, 23, 42, .9)'
  },

  // Horizontal Rule
  hr: {
    backgroundColor: '#E9ECEF',
    height: 1
  },

  // Emphasis
  strong: {
    fontWeight: 'normal',
    fontFamily: 'mulish-bold'
  },
  em: {
    fontStyle: 'normal',
    fontFamily: 'mulish-italic'
  },
  s: {
    textDecorationLine: 'line-through'
  },

  // Blockquotes
  blockquote: {
    backgroundColor: '#F5F5F5',
    borderColor: '#5836cc',
    borderLeftWidth: 4,
    marginLeft: 1,
    paddingTop: 16,
    paddingBottom: 0,
    paddingHorizontal: 16,
    marginBottom: 16
  },

  // Lists
  bullet_list: { marginVertical: 16 },
  ordered_list: { marginVertical: 16 },
  list_item: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    marginBottom: 8
  },
  // @pseudo class, does not have a unique render rule
  bullet_list_icon: {
    marginLeft: 10,
    marginRight: 10
  },
  // @pseudo class, does not have a unique render rule
  bullet_list_content: {
    flex: 1
  },
  // @pseudo class, does not have a unique render rule
  ordered_list_icon: {
    marginLeft: 10,
    marginRight: 10
  },
  // @pseudo class, does not have a unique render rule
  ordered_list_content: {
    flex: 1
  },

  // Code
  code_inline: {
    borderWidth: 1,
    borderColor: '#CCCCCC',
    backgroundColor: '#f5f5f5',
    padding: 10,
    borderRadius: 4,
    ...Platform.select({
      ['ios']: {
        fontFamily: 'Courier'
      },
      ['android']: {
        fontFamily: 'monospace'
      }
    })
  },
  code_block: {
    borderWidth: 1,
    borderColor: '#CCCCCC',
    backgroundColor: '#f5f5f5',
    padding: 10,
    borderRadius: 4,
    ...Platform.select({
      ['ios']: {
        fontFamily: 'Courier'
      },
      ['android']: {
        fontFamily: 'monospace'
      }
    })
  },
  fence: {
    borderWidth: 1,
    borderColor: '#CCCCCC',
    backgroundColor: '#f5f5f5',
    padding: 10,
    borderRadius: 4,
    ...Platform.select({
      ['ios']: {
        fontFamily: 'Courier'
      },
      ['android']: {
        fontFamily: 'monospace'
      }
    })
  },

  // Tables
  table: {
    borderWidth: 0,
    borderColor: '#E9ECEF',
    borderRadius: 0,
    marginTop: 10,
    marginBottom: 20
  },
  thead: {},
  tbody: {},
  th: {
    flex: 1,
    paddingVertical: 7,
    paddingHorizontal: 10,
    borderBottomWidth: 2,
    color: 'rgba(15, 23, 42, .9)',
    justifyContent: 'center'
  },
  tr: {
    borderBottomWidth: 1,
    borderColor: '#E9ECEF',
    flexDirection: 'row'
  },
  td: {
    flex: 1,
    paddingVertical: 7,
    paddingHorizontal: 10
  },

  // Links
  link: {
    color: '#5836cc',
    textDecorationLine: 'none',
    fontWeight: 'normal',
    fontFamily: 'mulish-bold'
  },
  blocklink: {
    flex: 1,
    borderColor: '#5836cc',
    borderBottomWidth: 1
  },

  // Images
  image: {
    flex: 1
  },

  // Text Output
  text: {},
  textgroup: {},
  paragraph: {
    marginTop: 0,
    marginBottom: 16,
    flexWrap: 'wrap',
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    width: '100%'
  },
  hardbreak: {
    width: '100%',
    height: 1
  },
  softbreak: {},

  // Believe these are never used but retained for completeness
  pre: {},
  inline: {},
  span: {}
})

export { tagsStyles, classesStyles, bodyStyles }
