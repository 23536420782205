import { View, StyleSheet } from 'react-native'
import React from 'react'

const CategoryItemSkeleton = () => {
  return (
    <View style={styles.listItem}>
      <View style={styles.title}></View>
    </View>
  )
}

const styles = StyleSheet.create({
  listItem: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-start',
    paddingVertical: 21,
    paddingHorizontal: 16,
    borderBottomWidth: 1,
    borderColor: '#E9ECEF'
  },
  title: {
    backgroundColor: 'rgba(233, 236, 239, 1)',
    width: '100%',
    padding: 6,
    borderRadius: 10,
    marginTop: 1
  }
})

export default CategoryItemSkeleton
