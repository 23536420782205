import { View, Text, StyleSheet, Image, Dimensions } from 'react-native'
import React from 'react'

// styles
import globalStyles from '../styles/global.js'

const DEVICE_WIDTH = Dimensions.get('window').width

const FeaturedPostItemSkeleton = () => {
  const getFeaturedImage = () => {
    return require('../../assets/images/default_thumb_reverse.png')
  }

  return (
    <View>
      <View style={styles.slidesWrapper}>
        <Image
          source={getFeaturedImage()}
          style={{
            width: DEVICE_WIDTH,
            height: DEVICE_WIDTH / 1.6
          }}
        />
        <View style={styles.date}>
          <View style={styles.dateText}></View>
        </View>
        <View style={styles.caption}>
          <View style={styles.captionText}></View>
          <View style={[styles.captionText, styles.captionTextShoter]}></View>
        </View>
        <View style={styles.slidesControl}>
          <View style={[styles.dot, styles.active]}></View>
          <View style={[styles.dot, styles.inactive]}></View>
          <View style={[styles.dot, styles.inactive]}></View>
          <View style={[styles.dot, styles.inactive]}></View>
          <View style={[styles.dot, styles.inactive]}></View>
        </View>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  slidesWrapper: {
    position: 'relative'
  },
  caption: {
    justifyContent: 'center',
    position: 'absolute',
    left: 0,
    bottom: 0,
    width: '100%',
    backgroundColor: 'rgba(15, 23, 42, 0.1)',
    paddingVertical: 14,
    paddingHorizontal: 16
  },
  captionText: {
    backgroundColor: 'rgba(233, 236, 239, 1)',
    width: '100%',
    padding: 6,
    borderRadius: 10,
    marginVertical: 5
  },
  captionTextShoter: {
    width: '60%'
  },
  date: {
    position: 'absolute',
    top: 0,
    right: 0,
    backgroundColor: 'rgba(15, 23, 42, 0.1)',
    paddingVertical: 12,
    paddingHorizontal: 13,
    borderBottomLeftRadius: 10
  },
  dateText: {
    backgroundColor: 'rgba(233, 236, 239, 1)',
    width: 60,
    padding: 4,
    borderRadius: 10
  },
  slidesControl: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 16,
    position: 'absolute',
    width: '100%',
    bottom: -40
  },
  dots: {
    backgroundColor: 'rgba(15, 23, 42, 0.1)',
    width: 100,
    height: 8,
    padding: 4,
    borderRadius: 10
  },
  dot: {
    backgroundColor: 'rgba(15, 23, 42, 1)',
    borderRadius: 20,
    marginHorizontal: 7
  },
  inactive: {
    backgroundColor: 'rgba(15, 23, 42, .05)',
    width: 10,
    height: 10
  },
  active: {
    backgroundColor: 'rgba(15, 23, 42, .2)',
    width: 20,
    height: 20
  }
})

export default FeaturedPostItemSkeleton
