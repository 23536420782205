import {
  View,
  Text,
  StyleSheet,
  Image,
  FlatList,
  TouchableOpacity,
  ActivityIndicator,
  Platform
} from 'react-native'
import React, { useState, useEffect } from 'react'
import moment from 'moment'
import { useNavigation } from '@react-navigation/native'

// styles
import globalStyles from '../styles/global.js'

// components
import PostItemSkeleton from '../skeletons/PostItem'

// hooks
import { useFetch } from '../hooks/useFetch'

// config
import { PER_PAGE } from '../../config'

const PostList = ({
  page,
  setPage,
  totalPages,
  setTotalPages,
  totalItems,
  setTotalItems,
  fetchMorePosts,
  latestPosts,
  setLatestPosts,
  reachedToEnd,
  setReachedToEnd,
  firstFetch,
  setFirstFetch,
  navDetails
}) => {
  const [firstLoad, setFirstLoad] = useState(true)
  const [isFetching, setIsFetching] = useState(false)

  const { data, loading, error } = useFetch(
    `/posts?per_page=${PER_PAGE}&page=${page}`,
    firstFetch
  )

  const getThumbnail = (uri) => {
    if (uri) return { uri }

    return require('../../assets/images/default_thumb_reverse.png')
  }

  // TODO - source: https://www.codegrepper.com/code-examples/javascript/onrefresh+flatlist+react+native
  const onRefresh = () => {
    setIsFetching(true)

    // re-init latest posts
    setPage(1)
    setTotalPages(1)
    setLatestPosts([])
    setReachedToEnd(false)
    setFirstFetch(true)
    setFirstLoad(true)

    // console.log('refreshing....')
    // console.log('page:', page)
    // console.log('totalPages:', totalPages)
    // console.log('reachedToEnd:', reachedToEnd)
    // console.log('firstFetch:', firstFetch)
    // console.log('firstLoad:', firstLoad)

    setIsFetching(false)
  }

  useEffect(() => {
    if (data) {
      setLatestPosts([...latestPosts, ...data.items])
      setTotalPages(data.meta.total_pages)
      setTotalItems(data.meta.total_items)
      setFirstLoad(false)
    }
  }, [data])

  if (loading && firstLoad)
    return (
      <FlatList
        data={[
          { id: Math.random() },
          { id: Math.random() },
          { id: Math.random() },
          { id: Math.random() },
          { id: Math.random() }
        ]}
        keyExtractor={(item) => item.id}
        ListHeaderComponent={() => (
          <Text style={[globalStyles.headerText, globalStyles.textBold]}>
            Latest Posts
          </Text>
        )}
        renderItem={() => <PostItemSkeleton />}
      />
    )

  if (error)
    return (
      <View
        style={{
          flex: 1,
          alignItems: 'center',
          paddingTop: 80
        }}
      >
        <Text style={globalStyles.textUppercase}>No posts to display</Text>
      </View>
    )

  return (
    data && (
      <FlatList
        data={latestPosts}
        keyExtractor={(item) => item.id + Math.random()}
        ListHeaderComponent={() => (
          <Text style={[globalStyles.headerText, globalStyles.textBold]}>
            Latest Posts
          </Text>
        )}
        renderItem={({ item }) => {
          return (
            <TouchableOpacity
              style={styles.itemList}
              onPress={() => navDetails(item)}
            >
              <Image
                source={getThumbnail(item.images.thumb)}
                style={styles.itemImg}
              ></Image>
              <View style={styles.itemListText}>
                <View style={{ flex: 1 }}>
                  <Text
                    style={[
                      globalStyles.textBold,
                      globalStyles.primaryColor,
                      { lineHeight: 19 }
                    ]}
                    numberOfLines={2}
                  >
                    {item.title}
                  </Text>
                </View>
                <View style={styles.date}>
                  <Text
                    style={[
                      styles.dateText,
                      globalStyles.text,
                      globalStyles.secondaryColor
                    ]}
                  >
                    By {item.author} on{' '}
                    {moment(item.created_at).format('MMM Do, yyyy')}
                  </Text>
                </View>
              </View>
            </TouchableOpacity>
          )
        }}
        onEndReached={fetchMorePosts}
        onEndReachedThreshold={0}
        ListFooterComponent={
          Platform.OS !== 'web'
            ? () => {
                return reachedToEnd || totalItems <= PER_PAGE ? (
                  <View
                    style={[
                      styles.footerText,
                      globalStyles.textCenter,
                      globalStyles.textLight,
                      globalStyles.secondaryColor
                    ]}
                  >
                    {/* No more posts */}
                  </View>
                ) : (
                  <View
                    style={[
                      styles.footerText,
                      globalStyles.textCenter,
                      globalStyles.textLight,
                      globalStyles.primaryColor
                    ]}
                  >
                    <ActivityIndicator
                      size="small"
                      color="rgba(15, 23, 42, 1)"
                    />
                  </View>
                )
              }
            : () => {
                return reachedToEnd || totalItems <= PER_PAGE ? (
                  <Text
                    style={[
                      styles.footerText,
                      globalStyles.textCenter,
                      globalStyles.textLight,
                      globalStyles.secondaryColor,
                      globalStyles.textUppercase
                    ]}
                  >
                    {/* No more posts */}
                  </Text>
                ) : (
                  <TouchableOpacity onPress={fetchMorePosts}>
                    <Text
                      style={[
                        styles.footerText,
                        globalStyles.textCenter,
                        globalStyles.textLight,
                        globalStyles.primaryColor,
                        globalStyles.textUppercase
                      ]}
                    >
                      Load more posts
                    </Text>
                  </TouchableOpacity>
                )
              }
        }
        // ListFooterComponent={() => {
        //   return reachedToEnd || totalItems <= PER_PAGE ? (
        //     <Text
        //       style={[
        //         styles.footerText,
        //         globalStyles.textCenter,
        //         globalStyles.textLight,
        //         globalStyles.secondaryColor,
        //         globalStyles.textUppercase
        //       ]}
        //     >
        //       {/* No more posts */}
        //     </Text>
        //   ) : (
        //     <TouchableOpacity onPress={fetchMorePosts}>
        //       <Text
        //         style={[
        //           styles.footerText,
        //           globalStyles.textCenter,
        //           globalStyles.textLight,
        //           globalStyles.primaryColor,
        //           globalStyles.textUppercase
        //         ]}
        //       >
        //         Load more posts
        //       </Text>
        //     </TouchableOpacity>
        //   )
        // }}
        onRefresh={onRefresh}
        refreshing={isFetching}
      />
    )
  )
}

const styles = StyleSheet.create({
  itemList: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-start',
    paddingVertical: 12,
    paddingHorizontal: 16,
    borderBottomWidth: 1,
    borderColor: '#E9ECEF'
  },
  itemImg: {
    width: 120,
    height: 75,
    borderTopLeftRadius: 10,
    borderBottomRightRadius: 10
  },
  itemListText: {
    flex: 1,
    flexDirection: 'column',
    marginLeft: 16,
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    height: '100%'
  },
  date: {},
  dateText: {
    fontSize: 12
  },
  footerText: {
    marginVertical: 20
  },
  container: {
    flex: 1,
    justifyContent: 'center'
  },
  horizontal: {
    flexDirection: 'row',
    justifyContent: 'space-around',
    padding: 10
  },
  loadMore: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(88, 54, 204, .1)',
    color: 'rgba(88, 54, 204, 1)',
    paddingHorizontal: 6,
    paddingVertical: 12,
    borderRadius: 20
  }
})

export default PostList
