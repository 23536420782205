import { useState, useEffect } from 'react'
import axios from 'axios'

import client from '../api/client'

// Source: https://axios-http.com/docs/cancellation
const useFetch = (url, firstFetch = true) => {
  const cancelToken = axios.CancelToken
  const source = cancelToken.source()
  const controller = new AbortController()

  const [data, setData] = useState(null)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)

  useEffect(() => {
    setLoading(true)

    const fetchData = async () => {
      // simulate delay of 2 seconds
      if (firstFetch) {
        await new Promise((resolve) => {
          setTimeout(() => {
            resolve()
          }, 500)
        })
      }

      try {
        const { data } = await client.get(url, {
          cancelToken: source.token,
          signal: controller.signal
        })

        setData(data)
        setLoading(false)
        setError(null)
      } catch (err) {
        const { response } = err
        if (response?.data) {
          setError(response.data)
        } else {
          setError({ error: err.message || err })
        }
        setLoading(false)
      }
    }

    fetchData()

    return () => {
      source.cancel('axios request cancelled')
      controller.abort()
    }
  }, [url])

  return { data, loading, error }
}

export { useFetch }
