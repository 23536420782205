import axios from 'axios'

// config
import { API_BASE_URL } from '../../config'

const client = axios.create({
  baseURL: API_BASE_URL
})

export default client
