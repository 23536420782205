import { StatusBar } from 'expo-status-bar'
import AppLoading from 'expo-app-loading'
import { useFonts } from 'expo-font'
import { View, Text, StyleSheet } from 'react-native'
import React from 'react'
import { NavigationContainer, DefaultTheme } from '@react-navigation/native'
import Constants from 'expo-constants'

// navigation
import TabNavigator from './src/navigations/TabNavigator'

const App = () => {
  let [fontsLoaded] = useFonts({
    // lato
    'lato-thin-italic': require('./assets/fonts/Lato/Lato-ThinItalic.ttf'),
    'lato-think': require('./assets/fonts/Lato/Lato-Thin.ttf'),
    'lato-regular': require('./assets/fonts/Lato/Lato-Regular.ttf'),
    'lato-light-italic': require('./assets/fonts/Lato/Lato-LightItalic.ttf'),
    'lato-light': require('./assets/fonts/Lato/Lato-Light.ttf'),
    'lato-italic': require('./assets/fonts/Lato/Lato-Italic.ttf'),
    'lato-bold-italic': require('./assets/fonts/Lato/Lato-BoldItalic.ttf'),
    'lato-bold': require('./assets/fonts/Lato/Lato-Bold.ttf'),
    'lato-black-italic': require('./assets/fonts/Lato/Lato-BlackItalic.ttf'),
    'lato-black': require('./assets/fonts/Lato/Lato-Black.ttf'),

    // mulish
    'mulish-light': require('./assets/fonts/Mulish/Mulish-Light.ttf'),
    'mulish-regular': require('./assets/fonts/Mulish/Mulish-Regular.ttf'),
    'mulish-medium': require('./assets/fonts/Mulish/Mulish-Medium.ttf'),
    'mulish-semibold': require('./assets/fonts/Mulish/Mulish-SemiBold.ttf'),
    'mulish-bold': require('./assets/fonts/Mulish/Mulish-Bold.ttf'),
    'mulish-italic': require('./assets/fonts/Mulish/Mulish-Italic.ttf')
  })

  const CUSTOM_THEME = {
    ...DefaultTheme,
    colors: { ...DefaultTheme.colors, background: '#fff' }
  }

  if (!fontsLoaded) return <AppLoading />

  return (
    <NavigationContainer theme={CUSTOM_THEME}>
      <TabNavigator />
      <StatusBar style="dark" />
    </NavigationContainer>
  )
}

export default App
