import { StyleSheet, Text, View, ScrollView } from 'react-native'
import React from 'react'

// components
import CategoryList from '../components/CategoryList'

const CategoryListScreen = ({ navigation }) => {
  const navDetails = (catId, catName, catDesc) => {
    navigation.navigate('CategoryScreen', {
      catId,
      catName,
      catDesc
    })
  }

  return (
    <ScrollView>
      <CategoryList navDetails={navDetails} />
    </ScrollView>
  )
}

const styles = StyleSheet.create({})

export default CategoryListScreen
