import { View, Text, StyleSheet, Image } from 'react-native'
import React from 'react'

// styles
import globalStyles from '../styles/global.js'

const PostItemSkeleton = ({ gap = true }) => {
  const getThumbnail = () => {
    return require('../../assets/images/default_thumb_reverse.png')
  }

  return (
    <View
      style={[styles.itemList, gap ? styles.itemListGap : styles.noItemListGap]}
    >
      <Image source={getThumbnail()} style={styles.itemImg}></Image>
      <View style={styles.itemListDesc}>
        <View style={styles.itemListTitle}>
          <View style={styles.title}></View>
          <View style={[styles.title, styles.titleShorter]}></View>
        </View>
        <View style={styles.date}></View>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  itemList: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-start',
    paddingVertical: 12,
    // paddingHorizontal: 16,
    borderBottomWidth: 1,
    borderColor: 'rgba(0, 0, 0, 0.1)'
  },
  itemListGap: {
    paddingHorizontal: 16
  },
  noItemListGap: {
    paddingHorizontal: 0
  },
  itemImg: {
    width: 120,
    height: 75,
    borderTopLeftRadius: 10,
    borderBottomRightRadius: 10
  },
  itemListDesc: {
    flex: 1,
    flexDirection: 'column',
    marginLeft: 16,
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    height: '100%'
  },
  itemListTitle: {
    flex: 1,
    width: '100%'
  },
  title: {
    // backgroundColor: 'rgba(0, 0, 0, 0.1)',
    backgroundColor: 'rgba(233, 236, 239, 1)',
    width: '100%',
    padding: 6,
    borderRadius: 10,
    marginBottom: 5
  },
  titleShorter: {
    width: '80%'
  },
  date: {
    // backgroundColor: 'rgba(0, 0, 0, 0.05)',
    backgroundColor: 'rgba(233, 236, 239, .5)',
    width: '60%',
    borderRadius: 10,
    padding: 6
  }
})

export default PostItemSkeleton
