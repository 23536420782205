import {
  StyleSheet,
  Text,
  View,
  Image,
  FlatList,
  TouchableOpacity
} from 'react-native'
import React, { useState, useEffect } from 'react'
import moment from 'moment'

// styles
import globalStyles from '../styles/global.js'

// components
import PostItemSkeleton from '../skeletons/PostItem'

// hooks
import { useFetch } from '../hooks/useFetch'

// config
import { PER_PAGE } from '../../config'

const SearchList = ({
  term,
  page,
  setPage,
  totalPages,
  setTotalPages,
  totalItems,
  setTotalItems,
  fetchMorePosts,
  latestPosts,
  setLatestPosts,
  reachedToEnd,
  setReachedToEnd,
  firstFetch,
  setFirstFetch,
  navDetails
}) => {
  const [firstLoad, setFirstLoad] = useState(true)
  const [isFetching, setIsFetching] = useState(false)

  const { data, loading, error } = useFetch(
    `/posts?per_page=${PER_PAGE}&page=${page}&term=${term}`,
    firstFetch
  )

  const getThumbnail = (uri) => {
    if (uri) return { uri }

    return require('../../assets/images/default_thumb_reverse.png')
  }

  useEffect(() => {
    if (data) {
      setLatestPosts([...latestPosts, ...data.items])
      setTotalPages(data.meta.total_pages)
      setTotalItems(data.meta.total_items)
      setFirstLoad(false)
    }
  }, [data])

  if (loading && firstLoad)
    return (
      <FlatList
        data={[
          { id: Math.random() },
          { id: Math.random() },
          { id: Math.random() },
          { id: Math.random() },
          { id: Math.random() }
        ]}
        keyExtractor={(item) => item.id}
        renderItem={() => <PostItemSkeleton />}
      />
    )

  //   if (!loading && firstLoad)
  //     return (
  //       <View>
  //         <PostItemSkeleton />
  //         <PostItemSkeleton />
  //         <PostItemSkeleton />
  //       </View>
  //     )

  if (error)
    return (
      <View
        style={{
          flex: 1,
          alignItems: 'center',
          paddingTop: 80
        }}
      >
        <Text style={globalStyles.textUppercase}>No posts to display</Text>
      </View>
    )

  return data && data.meta.total_items > 0 ? (
    <FlatList
      data={latestPosts}
      keyExtractor={(item) => item.id + Math.random()}
      renderItem={({ item }) => {
        return (
          <TouchableOpacity
            style={styles.itemList}
            onPress={() => navDetails(item)}
          >
            <Image
              source={getThumbnail(item.images.thumb)}
              style={styles.itemImg}
            ></Image>
            <View style={styles.itemListText}>
              <View style={{ flex: 1 }}>
                <Text
                  style={[
                    globalStyles.textBold,
                    globalStyles.primaryColor,
                    { lineHeight: 19 }
                  ]}
                  numberOfLines={2}
                >
                  {item.title}
                </Text>
              </View>
              <View style={styles.date}>
                <Text
                  style={[
                    styles.dateText,
                    globalStyles.text,
                    globalStyles.secondaryColor
                  ]}
                >
                  By {item.author} on{' '}
                  {moment(item.created_at).format('MMM Do, yyyy')}
                </Text>
              </View>
            </View>
          </TouchableOpacity>
        )
      }}
      // onEndReached={fetchMorePosts}
      // onEndReachedThreshold={0}
      // ListFooterComponent={() => {
      //   return reachedToEnd || totalItems <= PER_PAGE ? (
      //     <Text
      //       style={[
      //         styles.footerText,
      //         globalStyles.textCenter,
      //         globalStyles.textLight,
      //         globalStyles.secondaryColor
      //       ]}
      //     >
      //       No more posts
      //     </Text>
      //   ) : (
      //     <Text
      //       style={[
      //         styles.footerText,
      //         globalStyles.textCenter,
      //         globalStyles.textLight,
      //         globalStyles.primaryColor
      //       ]}
      //     >
      //       <ActivityIndicator size="small" color="rgba(15, 23, 42, 1)" />
      //     </Text>
      //   )
      // }}
      ListFooterComponent={() => {
        return reachedToEnd || totalItems <= PER_PAGE ? (
          <Text
            style={[
              styles.footerText,
              globalStyles.textCenter,
              globalStyles.textLight,
              globalStyles.secondaryColor,
              globalStyles.textUppercase
            ]}
          >
            {/* No more posts */}
          </Text>
        ) : (
          <TouchableOpacity onPress={fetchMorePosts}>
            <Text
              style={[
                styles.footerText,
                globalStyles.textCenter,
                globalStyles.textLight,
                globalStyles.primaryColor,
                globalStyles.textUppercase
              ]}
            >
              Load more posts
            </Text>
          </TouchableOpacity>
        )
      }}
    />
  ) : (
    <View style={{ flex: 1 }}>
      <Text
        style={[
          globalStyles.text,
          globalStyles.textUppercase,
          globalStyles.textCenter,
          { marginTop: 20 }
        ]}
      >
        No Posts Found
      </Text>
    </View>
  )
}

const styles = StyleSheet.create({
  itemList: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-start',
    paddingVertical: 12,
    paddingHorizontal: 16,
    borderBottomWidth: 1,
    borderColor: '#E9ECEF'
  },
  itemImg: {
    width: 120,
    height: 75,
    borderTopLeftRadius: 10,
    borderBottomRightRadius: 10
  },
  itemListText: {
    flex: 1,
    flexDirection: 'column',
    marginLeft: 16,
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    height: '100%'
  },
  date: {},
  dateText: {
    fontSize: 12
  },
  footerText: {
    marginVertical: 20
  },
  container: {
    flex: 1,
    justifyContent: 'center'
  },
  horizontal: {
    flexDirection: 'row',
    justifyContent: 'space-around',
    padding: 10
  }
})

export default SearchList
