import {
  StyleSheet,
  Text,
  View,
  SafeAreaView,
  TouchableOpacity,
  TouchableHighlight,
  TextInput,
  Form
} from 'react-native'
import React, { useState, useEffect, useRef } from 'react'
import Constants from 'expo-constants'
import { AntDesign } from '@expo/vector-icons'

// styles
import globalStyles from '../styles/global.js'
import SearchList from '../components/SearchList.js'
import PostFilterTitle from '../components/PostFilterTitle.js'

const SearchScreen = ({ navigation }) => {
  const [term, setTerm] = useState('')
  const [icon, setIcon] = useState('search1')
  const [isSubmit, setIsSubmit] = useState(false)

  const [page, setPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  const [totalItems, setTotalItems] = useState(0)
  const [latestPosts, setLatestPosts] = useState([])
  const [reachedToEnd, setReachedToEnd] = useState(false)
  const [firstFetch, setFirstFetch] = useState(true)

  const searchInput = useRef()

  const fetchMorePosts = () => {
    if (reachedToEnd) return

    setPage((prevPage) => prevPage + 1)
    setFirstFetch(false)

    // if (page === totalPages) setReachedToEnd(true)
    if (page === totalPages - 1) setReachedToEnd(true)
  }

  const navDetails = (post) => {
    navigation.navigate('PostDetailsScreen', {
      postId: post.id
    })
  }

  const handleChange = (text) => {
    setIsSubmit(false)
    setTerm(text)
    if (text) setIcon('close')
    else setIcon('search1')
  }

  const handlePress = () => {
    if (term) {
      setTerm('')
      setIcon('search1')
    }
  }

  const handleSubmit = () => {
    setLatestPosts([])
    setFirstFetch(true)
    setReachedToEnd(false)
    setPage(1)
    setIsSubmit(true)
  }

  useEffect(() => {
    setTerm('')
    searchInput.current.focus()
  }, [])

  return (
    <View style={[styles.searchContainer]}>
      <View style={styles.searchBar}>
        <TextInput
          placeholder="Search for posts"
          value={term}
          onChangeText={handleChange}
          style={[styles.input, globalStyles.text]}
          ref={searchInput}
          onSubmitEditing={handleSubmit}
        />
        <TouchableOpacity style={styles.inputIcon} onPress={handlePress}>
          <AntDesign name={icon} size={24} color="rgba(15, 23, 42, .25)" />
        </TouchableOpacity>
      </View>
      {term && isSubmit ? (
        <View style={styles.searchResult}>
          <PostFilterTitle term={term} filterBy="term" size={totalItems} />
          <SearchList
            term={term}
            page={page}
            setPage={setPage}
            totalPages={totalPages}
            setTotalPages={setTotalPages}
            totalItems={totalItems}
            setTotalItems={setTotalItems}
            fetchMorePosts={fetchMorePosts}
            latestPosts={latestPosts}
            setLatestPosts={setLatestPosts}
            reachedToEnd={reachedToEnd}
            setReachedToEnd={setReachedToEnd}
            firstFetch={firstFetch}
            setFirstFetch={setFirstFetch}
            navDetails={navDetails}
          />
        </View>
      ) : (
        <View></View>
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  searchContainer: {
    flex: 1,
    paddingTop: Constants.statusBarHeight,
    marginTop: 16
  },
  input: {
    borderWidth: 1,
    backgroundColor: '#fff',
    borderColor: 'rgba(15, 23, 42, .15)',
    color: 'rgba(15, 23, 42, 1)',
    paddingLeft: 16,
    paddingRight: 40,
    paddingVertical: 10,
    fontSize: 18,
    lineHeight: 24,
    borderTopLeftRadius: 10,
    borderBottomRightRadius: 10
  },
  inputIcon: {
    position: 'absolute',
    right: 12,
    top: 12
  },
  searchBar: {
    position: 'relative',
    marginHorizontal: 16
  },
  searchResult: {
    flex: 1,
    marginVertical: 26
  }
})

export default SearchScreen
