import {
  View,
  Text,
  StyleSheet,
  Image,
  useWindowDimensions
} from 'react-native'
import React from 'react'

// styles
import globalStyles from '../styles/global.js'

const PostDetailstItemSkeleton = () => {
  const { width } = useWindowDimensions()

  const getFeaturedImage = () => {
    return require('../../assets/images/default_thumb_reverse.png')
  }

  return (
    <View>
      <View style={styles.slidesWrapper}>
        <Image
          source={getFeaturedImage()}
          style={{
            width,
            height: width / 1.6
          }}
        />
        <View style={styles.dateCaption}>
          <View style={styles.dateCaptionText}></View>
        </View>
      </View>
      <View style={globalStyles.container}>
        <View style={styles.title}></View>
        <View style={[styles.title, styles.titleShoter]}></View>
        <View style={styles.date}></View>
        <View style={styles.body}></View>
        <View style={styles.body}></View>
        <View style={styles.body}></View>
        <View style={styles.body}></View>
        <View style={styles.body}></View>
        <View style={styles.body}></View>
        <View style={[styles.body, styles.bodyShoter]}></View>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  slidesWrapper: {
    position: 'relative'
  },
  title: {
    backgroundColor: 'rgba(233, 236, 239, 1)',
    width: '100%',
    padding: 9,
    borderRadius: 10,
    marginVertical: 5
  },
  titleShoter: {
    width: '60%'
  },
  dateCaption: {
    position: 'absolute',
    top: 0,
    right: 0,
    backgroundColor: 'rgba(15, 23, 42, 0.1)',
    paddingVertical: 12,
    paddingHorizontal: 13,
    borderBottomLeftRadius: 10
  },
  dateCaptionText: {
    backgroundColor: 'rgba(233, 236, 239, 1)',
    width: 60,
    padding: 4,
    borderRadius: 10
  },
  date: {
    backgroundColor: 'rgba(233, 236, 239, .5)',
    width: '70%',
    padding: 6,
    borderRadius: 10,
    marginTop: 10,
    marginBottom: 20
  },
  body: {
    backgroundColor: 'rgba(233, 236, 239, .7)',
    width: '100%',
    padding: 5,
    borderRadius: 10,
    marginVertical: 5
  },
  bodyShoter: {
    width: '40%'
  }
})

export default PostDetailstItemSkeleton
