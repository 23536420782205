import {
  Text,
  View,
  StyleSheet,
  Image,
  Dimensions,
  TouchableOpacity,
  Platform
} from 'react-native'
import React from 'react'
import { SwiperFlatList } from 'react-native-swiper-flatlist'
import { MaterialIcons } from '@expo/vector-icons'
import { AntDesign } from '@expo/vector-icons'

import moment from 'moment'

// styles
import globalStyles from '../styles/global.js'

// components
import FeaturedPostItemSkeleton from '../skeletons/FeaturedPostItem'

// hooks
import { useFetch } from '../hooks/useFetch'
import { SafeAreaView } from 'react-native-safe-area-context'

// constants
const DEVICE_WIDTH = Dimensions.get('window').width

const FeaturedPosts = ({ navDetails }) => {
  const { data, loading, error } = useFetch('/featured-posts')

  let slideWidth = DEVICE_WIDTH
  let slideHeight = DEVICE_WIDTH / 1.6

  if (Platform.OS === 'web') {
    if (DEVICE_WIDTH > 1024) {
      slideWidth = slideWidth / 3
      slideHeight = slideHeight / 3
    } else if (DEVICE_WIDTH < 1024 && DEVICE_WIDTH > 768) {
      slideWidth = slideWidth / 2
      slideHeight = slideHeight / 2
    }
  }

  const getFeaturedImage = (uri) => {
    if (uri) return { uri }

    return require('../../assets/images/default_large_reverse.png')
  }

  if (loading) return <FeaturedPostItemSkeleton />
  // if (loading)
  //   return (
  //     <View>
  //       <Text>Loading...</Text>
  //     </View>
  //   )

  if (error)
    return (
      <View
        style={{
          flex: 1,
          alignItems: 'center',
          paddingTop: 80
        }}
      >
        <Text style={globalStyles.textUppercase}>No posts to display</Text>
      </View>
    )

  return (
    data && (
      <View>
        <SwiperFlatList
          index={0}
          paginationStyle={styles.slidesControl}
          paginationStyleItem={styles.dot}
          paginationStyleItemInactive={[styles.inactive]}
          paginationStyleItemActive={[styles.active]}
          data={data.items}
          renderItem={({ item }) => (
            <View style={styles.slidesWrapper}>
              <Image
                source={getFeaturedImage(item.images.large)}
                style={{
                  width: slideWidth,
                  height: slideHeight
                }}
              />
              <TouchableOpacity
                onPress={() => navDetails(item)}
                style={styles.caption}
              >
                <Text
                  style={[styles.captionText, globalStyles.text]}
                  numberOfLines={
                    Platform.OS === 'web' && DEVICE_WIDTH > 768 ? 1 : 2
                  }
                >
                  {item.title}
                </Text>
                {/* <MaterialIcons
                  name="arrow-forward-ios"
                  size={16}
                  color="white"
                /> */}
                <AntDesign name="rightcircleo" size={20} color="white" />
              </TouchableOpacity>
              <View style={styles.date}>
                <Text style={[styles.dateText, globalStyles.text]}>
                  {moment(item.created_at).fromNow()}
                </Text>
              </View>
            </View>
          )}
          autoplayDelay={3}
          showPagination={
            Platform.OS === 'web' && DEVICE_WIDTH > 768 ? false : true
          }
          autoplay={Platform.OS === 'web' ? true : false}
          autoplayLoop={Platform.OS === 'web' ? true : false}
        />
      </View>
    )
  )
}

const styles = StyleSheet.create({
  slidesWrapper: {
    position: 'relative'
  },
  caption: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    position: 'absolute',
    left: 0,
    bottom: 0,
    width: '100%',
    backgroundColor: 'rgba(15, 23, 42, 0.8)',
    paddingVertical: 16,
    paddingHorizontal: 16
  },
  captionText: {
    color: '#fff',
    fontSize: 16,
    lineHeight: 21,
    paddingRight: 16,
    flex: 1
  },
  date: {
    position: 'absolute',
    top: 0,
    right: 0,
    backgroundColor: 'rgba(15, 23, 42, 0.8)',
    paddingVertical: 8,
    paddingHorizontal: 12,
    borderBottomLeftRadius: 10
  },
  dateText: {
    color: '#fff',
    fontSize: 12
  },
  slidesControl: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    bottom: -50
  },
  dot: {
    backgroundColor: 'rgba(15, 23, 42, 1)',
    borderRadius: 20,
    marginHorizontal: 7
  },
  inactive: {
    backgroundColor: 'rgba(15, 23, 42, .2)',
    width: 10,
    height: 10
  },
  active: {
    backgroundColor: 'rgba(15, 23, 42, .8)',
    width: 20,
    height: 20
  }
})

export default FeaturedPosts
