import {
  StyleSheet,
  Text,
  View,
  TouchableWithoutFeedback,
  Platform
} from 'react-native'
import React from 'react'
import { createNativeStackNavigator } from '@react-navigation/native-stack'
import { AntDesign } from '@expo/vector-icons'

// components
import CategoryScreen from '../screens/CategoryScreen'
import CategoryListScreen from '../screens/CategoryListScreen'

// stack
const Stack = createNativeStackNavigator()

const CategoryStack = ({ navigation }) => {
  return (
    <Stack.Navigator
      screenOptions={{
        headerShadowVisible: false,
        headerStyle: {
          backgroundColor: '#fff'
        },
        headerTitleStyle: {
          fontFamily: 'mulish-bold',
          color: 'rgba(15, 23, 42, 1)'
        }
      }}
    >
      <Stack.Screen
        options={{
          headerShown: true,
          title: 'CATEGORIES',
          headerTitleAlign: 'center'
        }}
        name="CategoryListScreen"
        component={CategoryListScreen}
      />
      <Stack.Screen
        options={{
          headerShown: true,
          title: '',
          headerTitleAlign: 'center',
          headerLeft: () => (
            <View style={{ marginLeft: Platform.OS === 'web' && 16 }}>
              <AntDesign
                // name="arrowleft"
                name="leftcircleo"
                size={26}
                color="rgba(15, 23, 42, 1)"
                onPress={() => {
                  navigation.navigate('CategoryListScreen')
                }}
              />
            </View>
          )
        }}
        name="CategoryScreen"
        component={CategoryScreen}
      />
    </Stack.Navigator>
  )
}

const styles = StyleSheet.create({
  iconWrapper: {
    width: 40,
    height: 40,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 20
  }
})

export default CategoryStack
