import {
  View,
  Text,
  StyleSheet,
  Image,
  FlatList,
  TouchableOpacity
} from 'react-native'
import React from 'react'
import moment from 'moment'
import { useNavigation } from '@react-navigation/native'

// styles
import globalStyles from '../styles/global.js'

// components
import PostItemSkeleton from '../skeletons/PostItem'

// hooks
import { useFetch } from '../hooks/useFetch'

const RelatedPosts = ({ posts }) => {
  const { data, loading, error } = useFetch(`/posts?post_ids=${posts}`)

  const navigation = useNavigation()

  const getThumbnail = (uri) => {
    if (uri) return { uri }

    return require('../../assets/images/default_thumb_reverse.png')
  }

  const navDetails = (postId) => {
    navigation.navigate('PostDetailsScreen', { postId })
  }

  if (loading)
    return (
      <View>
        <PostItemSkeleton gap={false} />
        <PostItemSkeleton gap={false} />
        <PostItemSkeleton gap={false} />
      </View>
    )

  if (error)
    return (
      <View
        style={{
          flex: 1,
          alignItems: 'center',
          paddingTop: 80
        }}
      >
        <Text style={globalStyles.textUppercase}>No posts to display</Text>
      </View>
    )

  return (
    data &&
    (data.items || data.item).map((item) => (
      <TouchableOpacity
        style={styles.itemList}
        onPress={() => navDetails(item.id)}
        key={item.id + Math.random()}
      >
        <Image
          source={getThumbnail(item.images.thumb)}
          style={styles.itemImg}
        ></Image>
        <View style={styles.itemListText}>
          <View style={{ flex: 1 }}>
            <Text
              style={[
                globalStyles.textBold,
                globalStyles.primaryColor,
                { lineHeight: 19 }
              ]}
              numberOfLines={2}
            >
              {item.title}
            </Text>
          </View>
          <View style={styles.date}>
            <Text
              style={[
                styles.dateText,
                globalStyles.text,
                globalStyles.secondaryColor
              ]}
            >
              By {item.author} on{' '}
              {moment(item.created_at).format('MMM Do, yyyy')}
            </Text>
          </View>
        </View>
      </TouchableOpacity>
    ))
  )
}

const styles = StyleSheet.create({
  itemList: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-start',
    paddingVertical: 12,
    borderBottomWidth: 1,
    borderColor: '#E9ECEF'
  },
  itemImg: {
    width: 120,
    height: 75,
    borderTopLeftRadius: 10,
    borderBottomRightRadius: 10
  },
  itemListText: {
    flex: 1,
    flexDirection: 'column',
    marginLeft: 16,
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    height: '100%'
  },
  date: {},
  dateText: {
    fontSize: 12
  },
  footerText: {
    marginVertical: 20
  },
  container: {
    flex: 1,
    justifyContent: 'center'
  },
  horizontal: {
    flexDirection: 'row',
    justifyContent: 'space-around',
    padding: 10
  }
})

export default RelatedPosts
