import { StyleSheet, Text, View, TouchableOpacity } from 'react-native'
import React, { useState } from 'react'
import { AntDesign } from '@expo/vector-icons'

// styles
import globalStyles from '../styles/global'

// hooks
import { useFetch } from '../hooks/useFetch'

// skeletons
import CategoryItemSkeleton from '../skeletons/CategoryItem'

const CategoryList = ({ navDetails }) => {
  const { data, loading, error } = useFetch('/post-categories-list')

  if (loading) {
    return (
      <View>
        <CategoryItemSkeleton />
        <CategoryItemSkeleton />
        <CategoryItemSkeleton />
        <CategoryItemSkeleton />
        <CategoryItemSkeleton />
      </View>
    )
  }

  if (error)
    return (
      <View
        style={{
          flex: 1,
          alignItems: 'center',
          paddingTop: 80
        }}
      >
        <Text style={globalStyles.textUppercase}>No categories to display</Text>
      </View>
    )

  // console.log(data)

  return data && data.items.length > 0 ? (
    <View style={{ marginBottom: 20 }}>
      {data.items.map((item) => (
        <TouchableOpacity
          style={styles.listItem}
          onPress={() => navDetails(item.id, item.name, item.description)}
          key={item.id}
        >
          <Text
            style={[
              globalStyles.primaryColor,
              globalStyles.textBold,
              styles.listItemText
            ]}
          >
            {item.name}
          </Text>
          <View style={[styles.badgeWrap]}>
            <Text style={[globalStyles.textBold, styles.badge]}>
              {item.count}
            </Text>
          </View>
          {/* <AntDesign
            name="rightcircleo"
            size={20}
            color="rgba(15, 23, 42,.5)"
          />
           */}
        </TouchableOpacity>
      ))}
    </View>
  ) : (
    <View style={{ flex: 1 }}>
      <Text
        style={[
          globalStyles.text,
          globalStyles.textUppercase,
          globalStyles.textCenter,
          { marginTop: 20 }
        ]}
      >
        No Categories Found
      </Text>
    </View>
  )
}

const styles = StyleSheet.create({
  listItem: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-start',
    paddingVertical: 16,
    paddingHorizontal: 16,
    borderBottomWidth: 1,
    borderColor: '#E9ECEF'
  },
  listItemText: {
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    height: '100%',
    fontSize: 16
  },
  badgeWrap: {
    borderRadius: 50,
    backgroundColor: 'rgba(88, 54, 204, .1)',
    paddingTop: 3,
    paddingBottom: 4,
    paddingHorizontal: 10
  },
  badge: {
    color: 'rgba(88, 54, 204, 1)'
  }
})

export default CategoryList
