import { Text, View, StyleSheet, Button } from 'react-native'
import React from 'react'
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs'
import { AntDesign } from '@expo/vector-icons'
import { Feather } from '@expo/vector-icons'

// import { MaterialIcons } from '@expo/vector-icons'
// import { Ionicons } from '@expo/vector-icons'
import { getHeaderTitle } from '@react-navigation/elements'

// styles
import globalStyles from '../styles/global.js'

// components
import SearchScreen from '../screens/SearchScreen'

// stacks
import HomeStack from './HomeStack'
import CategoryStack from './CategoryStack.js'

const Tab = createBottomTabNavigator()

const TabNavigator = () => {
  return (
    <Tab.Navigator
      screenOptions={({ route }) => ({
        tabBarIcon: ({ focused, color, size }) => {
          let iconName

          if (route.name === 'Home') {
            iconName = focused ? 'home' : 'home'
          } else if (route.name === 'Search') {
            iconName = focused ? 'search1' : 'search1'
          } else if (route.name === 'Category') {
            iconName = focused ? 'ellipsis1' : 'ellipsis1'
          }

          return <AntDesign name={iconName} size={24} color={color} />
        },
        tabBarShowLabel: false,
        tabBarActiveTintColor: 'rgba(15, 23, 42, 1)',
        tabBarInactiveTintColor: 'rgba(15, 23, 42, .25)',
        tabBarActiveBackgroundColor: 'transparent',
        tabBarInactiveBackgroundColor: '#fff',
        tabBarStyle: {
          // height: 56,
          backgroundColor: '#fff',
          borderTopWidth: 1,
          borderTopColor: '#E9ECEF',
          elevation: 0
          // width: 900,
          // alignSelf: 'center',
          // flexDirection: 'row'
        },
        headerStyle: {
          backgroundColor: '#fff',
          borderBottomColor: '#E9ECEF',
          borderBottomWidth: 0,
          elevation: 0
        },
        headerTitleStyle: {
          fontFamily: 'mulish-bold',
          color: 'rgba(15, 23, 42, 1)'
        },
        tabBarBackground: () => <View style={styles.absoluteFill}></View>,
        headerShown: false
      })}
    >
      <Tab.Screen name="Home" component={HomeStack} />
      <Tab.Screen name="Search" component={SearchScreen} />
      <Tab.Screen name="Category" component={CategoryStack} />
    </Tab.Navigator>
  )
}

const styles = StyleSheet.create({
  absoluteFill: {
    height: 7,
    // backgroundColor: '#5902EC',
    backgroundColor: 'rgba(15, 23, 42, .8)'
  }
})
export default TabNavigator
