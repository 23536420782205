import {
  StyleSheet,
  Text,
  View,
  SafeAreaView,
  ScrollView,
  useWindowDimensions,
  TouchableOpacity,
  Image,
  Alert,
  Linking,
  Platform
} from 'react-native'
import React from 'react'
import moment from 'moment'
import Markdown from 'react-native-markdown-display'
import * as WebBrowser from 'expo-web-browser'
import { useNavigation } from '@react-navigation/native'

// constants
import { API_HOST } from '../../config'

// TODO: fix unshown images when we use this rules
const rules = {
  paragraph: (node, children, parent, styles) => (
    <Text key={node.key} style={styles.paragraph} selectable>
      {children}
    </Text>
  )
}

// styles
import globalStyles from '../styles/global.js'
import { bodyStyles } from '../styles/content.js'

// hooks
import { useFetch } from '../hooks/useFetch'

// components
import PostDetailstItemSkeleton from '../skeletons/PostDetailsItem'
import PostItemSkeleton from '../skeletons/PostItem'
import RelatedPosts from '../components/RelatedPosts'

const PostDetailsScreen = ({ route }) => {
  const navigation = useNavigation()
  const { postId, slug } = route.params
  const { width } = useWindowDimensions()

  // get single id by slug or post id
  const apiQuery = slug ? `slugs=${slug}` : `post_ids=${postId}`

  const { data, loading, error } = useFetch(`/posts?${apiQuery}`)

  const getFeaturedImage = (uri) => {
    if (uri) return { uri }

    return require('../../assets/images/default_large_reverse.png')
  }

  const handleOnLinkPress = async (url) => {
    if (url.includes(API_HOST)) {
      const slug = url.split(`${API_HOST}/`)[1]
      navigation.navigate('PostDetailsScreen', { slug })

      return false
    }

    const res = await Linking.canOpenURL(url)
    // if (res) Linking.openURL(url)
    if (res) WebBrowser.openBrowserAsync(url)
    else Alert.alert('Invalid URL', 'Can not open broken link!')
  }

  const navCategory = (catId, catName, catDesc) => {
    navigation.navigate('Category', {
      screen: 'CategoryScreen',
      params: {
        catId,
        catName,
        catDesc
      }
    })
  }

  if (loading) return <PostDetailstItemSkeleton />

  if (error)
    return (
      <View
        style={{
          flex: 1,
          alignItems: 'center',
          paddingTop: 80,
          top: 0
        }}
      >
        <Text style={globalStyles.textUppercase}>No post details</Text>
      </View>
    )

  return (
    data && (
      <ScrollView>
        {Platform.OS === 'web' && width > 768 ? (
          <View style={{ maxHeight: 400, overflow: 'hidden' }}>
            <Image
              source={getFeaturedImage(data.item[0].images.large)}
              style={{
                width,
                height: width / 1.6
              }}
            />
          </View>
        ) : (
          <View>
            <Image
              source={getFeaturedImage(data.item[0].images.large)}
              style={{
                width,
                height: width / 1.6
              }}
            />
          </View>
        )}
        <View style={styles.dateCaption}>
          <Text style={[styles.dateCaptionText, globalStyles.text]}>
            {/* {moment(data.item[0].created_at).fromNow()} */}
            {data.item[0].categories[0].name}
          </Text>
        </View>
        <View style={globalStyles.container}>
          {/* <TouchableOpacity
            style={styles.categoryBadgeWrapper}
            onPress={() =>
              navCategory(
                data.item[0].categories[0].id,
                data.item[0].categories[0].name,
                data.item[0].categories[0].description
              )
            }
          >
            <View style={styles.categoryBadge}>
              <Text style={[globalStyles.textLight, styles.categoryBadgeText]}>
                {data.item[0].categories[0].name}
              </Text>
            </View>
          </TouchableOpacity> */}

          <Text
            style={[
              globalStyles.primaryColor,
              globalStyles.textBold,
              styles.title
            ]}
          >
            {data.item[0].title}
          </Text>
          <Text
            style={[
              globalStyles.secondaryColor,
              globalStyles.textLight,
              styles.date
            ]}
          >
            Posted by {data.item[0].author} on{' '}
            {moment(data.item[0].created_at).format('MMM Do, yyyy')}
          </Text>
          <View style={styles.tags}>
            {data.item[0].tags &&
              data.item[0].tags.map((tag) => (
                <TouchableOpacity
                  key={tag.id}
                  onPress={() =>
                    navigation.navigate('PostTagsScreen', {
                      tagId: tag.id,
                      tagName: tag.name
                    })
                  }
                  style={[styles.tagTextWrap]}
                >
                  <Text style={styles.tagText}># {tag.name}</Text>
                </TouchableOpacity>
              ))}
          </View>
          {data.item[0].excerpt ? (
            <View>
              <View>
                <Text style={[globalStyles.text, styles.excerptText]}>
                  {data.item[0].excerpt}
                </Text>
              </View>
              <View
                style={[globalStyles.separator, { marginBottom: 20 }]}
              ></View>
            </View>
          ) : (
            <View></View>
          )}

          <Markdown
            rules={rules}
            style={bodyStyles}
            onLinkPress={handleOnLinkPress}
          >
            {data.item[0].content}
          </Markdown>
          {data.item[0].related_posts.length > 0 && (
            <View>
              <View style={globalStyles.separator}></View>
              <View>
                <Text
                  style={[
                    globalStyles.textBold,
                    globalStyles.heading,
                    globalStyles.primaryColor
                  ]}
                >
                  Related Posts
                </Text>
                <SafeAreaView style={styles.relatedPostsWrapper}>
                  <RelatedPosts posts={data.item[0].related_posts.join(',')} />
                </SafeAreaView>
              </View>
            </View>
          )}
        </View>
      </ScrollView>
    )
  )
}

const styles = StyleSheet.create({
  dateCaption: {
    position: 'absolute',
    top: 0,
    right: 0,
    backgroundColor: 'rgba(15, 23, 42, 0.8)',
    paddingVertical: 8,
    paddingHorizontal: 12,
    borderBottomLeftRadius: 10
  },
  dateCaptionText: {
    color: '#FFFFFF',
    fontSize: 12
  },
  title: { fontSize: 24, lineHeight: 29 },
  date: { marginVertical: 10, fontSize: 13, lineHeight: 18 },
  tags: {
    flex: 1,
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginBottom: 30
  },
  tagTextWrap: {
    borderRadius: 20,
    backgroundColor: '#E9ECEF',
    paddingVertical: 4,
    paddingHorizontal: 12,
    marginRight: 6,
    marginTop: 2
  },
  tagText: {
    fontFamily: 'mulish-regular',
    color: 'rgba(15, 23, 42, 0.6)',
    marginBottom: 3,
    fontSize: 12,
    lineHeight: 17,
    textTransform: 'lowercase'
  },
  content: { marginVertical: 16, fontSize: 16, lineHeight: 21 },
  relatedPostsWrapper: {
    paddingBottom: 40
  },
  excerptText: {
    fontSize: 17,
    lineHeight: 22,
    color: 'rgba(15, 23, 42, .8)'
    // textAlign: 'justify'
  },
  categoryBadgeWrapper: {
    flex: 1,
    alignItems: 'flex-end',
    marginBottom: 16
  },
  categoryBadge: {
    backgroundColor: 'rgba(15, 23, 42, .8)',
    paddingVertical: 6,
    paddingHorizontal: 12,
    borderTopLeftRadius: 10,
    borderBottomRightRadius: 10
  },
  categoryBadgeText: {
    color: '#fff',
    textTransform: 'lowercase'
  }
})

export default PostDetailsScreen
