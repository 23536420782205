import { View, Text, StyleSheet, SafeAreaView, ScrollView } from 'react-native'
import React, { useState } from 'react'
import Constants from 'expo-constants'

// styles
import globalStyles from '../styles/global.js'

// components
import FeaturedPosts from '../components/FeaturedPosts'
import PostList from '../components/PostList'

const HomeScreen = ({ navigation }) => {
  const [page, setPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  const [totalItems, setTotalItems] = useState(0)
  const [latestPosts, setLatestPosts] = useState([])
  const [reachedToEnd, setReachedToEnd] = useState(false)
  const [firstFetch, setFirstFetch] = useState(true)

  const fetchMorePosts = () => {
    if (reachedToEnd) return

    setPage((prevPage) => prevPage + 1)
    setFirstFetch(false)

    // if (page === totalPages) setReachedToEnd(true)
    if (page === totalPages - 1) setReachedToEnd(true)
  }

  const navDetails = (post) => {
    navigation.navigate('PostDetailsScreen', {
      postId: post.id
    })
  }

  return (
    <View
      style={{
        flex: 1
        // paddingTop: Constants.statusBarHeight,
      }}
    >
      {/* <Text style={[globalStyles.headerText, globalStyles.textBold]}>
        Featured Posts
      </Text> */}
      <FeaturedPosts navDetails={navDetails} />
      <View
        style={{
          flex: 1,
          paddingTop: 60
        }}
      >
        <PostList
          page={page}
          setPage={setPage}
          totalPages={totalPages}
          setTotalPages={setTotalPages}
          totalItems={totalItems}
          setTotalItems={setTotalItems}
          fetchMorePosts={fetchMorePosts}
          latestPosts={latestPosts}
          setLatestPosts={setLatestPosts}
          reachedToEnd={reachedToEnd}
          setReachedToEnd={setReachedToEnd}
          firstFetch={firstFetch}
          setFirstFetch={setFirstFetch}
          navDetails={navDetails}
        />
      </View>
    </View>
  )
}

const styles = StyleSheet.create({})

export default HomeScreen
